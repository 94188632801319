// Utility functions for cart calculations

// Function to get the price of a product
export const getPrice = (item) => {
    return item.product.productitem_DETAIL.product_DETAIL.unit_PRICE || 3999;
  };
  
  // Function to calculate total for a single item
  export const calculateTotal = (item) => {
    return getPrice(item) * item.quantity;
  };
  
  // Function to calculate subtotal of all items
  export const calculateSubtotal = (items) => {
    return items.reduce((subtotal, item) => subtotal + calculateTotal(item), 0);
  };
  
  // Optionally, you can add more functions for taxes, discounts, etc.
  export const calculateTax = (subtotal, taxPercentage) => {
    return subtotal * (taxPercentage / 100);
  };

  