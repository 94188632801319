import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faChevronRight, faBolt, faScrewdriverWrench, faBriefcaseMedical, faBaseball, faUserDoctor, faBaseballBatBall, faCameraRetro, faFootball, faSun, faGears } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

import './Index.css'
import features from '../../data/Features.json'
import top_categories from '../../data/TopCategories.json'
import MainSlider from '../../components/mainSlider/mainSlider'

const Index = () => {

  return (
    <div className='home_page'>

      <section className='home_hero_section'>
        <div className='departments'>

          <div className='heading'>
            <FontAwesomeIcon icon={faList} />
            <h4>SHOP BY DEPARTMENT</h4>
          </div>

          <div className='dep_wrapper'>

            <Link to='/shop' className='category'>
              <div className='category_name'>
                <FontAwesomeIcon icon={faBolt} />
                <p>Electronic & Digital</p>
              </div>
              <FontAwesomeIcon className='left' icon={faChevronRight} />
            </Link>

            <Link to='/shop' className='category'>
              <div className='category_name'>
                <FontAwesomeIcon icon={faScrewdriverWrench} />
                <p>Accessories</p>
              </div>
              <FontAwesomeIcon className='left' icon={faChevronRight} />
            </Link>

            <Link to='/shop' className='category'>
              <div className='category_name'>
                <FontAwesomeIcon icon={faBriefcaseMedical} />
                <p>Home & Kitchen</p>
              </div>
              <FontAwesomeIcon className='left' icon={faChevronRight} />
            </Link>

            <Link to='/shop' className='category'>
              <div className='category_name'>
                <FontAwesomeIcon icon={faBaseball} />
                <p>Sport & Outdoors</p>
              </div>
              <FontAwesomeIcon className='left' icon={faChevronRight} />
            </Link>

            <Link to='/shop' className='category'>
              <div className='category_name'>
                <FontAwesomeIcon icon={faUserDoctor} />
                <p>Health & Beauty</p>
              </div>
            </Link>

            <Link to='/shop' className='category'>
              <div className='category_name'>
                <FontAwesomeIcon icon={faBaseballBatBall} />
                <p>Toys & Hobbies</p>
              </div>
            </Link>

            <Link to='/shop' className='category'>
              <div className='category_name'>
                <FontAwesomeIcon icon={faCameraRetro} />
                <p>Cameras & Camcorders</p>
              </div>
            </Link>

            <Link to='/shop' className='category'>
              <div className='category_name'>
                <FontAwesomeIcon icon={faFootball} />
                <p>Sport & Outdoors</p>
              </div>
            </Link>

            <Link to='/shop' className='category'>
              <div className='category_name'>
                <FontAwesomeIcon icon={faSun} />
                <p>Lightings</p>
              </div>
            </Link>

            <Link to='/shop' className='category'>
              <div className='category_name'>
                <FontAwesomeIcon icon={faGears} />
                <p>Managed</p>
              </div>
            </Link>

          </div>
        </div>

        <div className='hero_wrapper'>
          <MainSlider />
        </div>

      </section>

      <section className='features'>
        <div className='features_wrapper'>
          {features.map((feature) => (
            <div key={feature.id} className='feature_container'>
              <div>
                <img src={feature.image} alt='image' />
              </div>
              <div className='feature_content'>
                <h4>{feature.title}</h4>
                <p>{feature.disp}</p>
              </div>
              <hr />
            </div>
          ))}
        </div>
      </section>

      <section className='banner_section_1'>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_1.jpg' alt='banner 1' />
          </Link>
        </div>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_2.jpg' alt='banner 2' />
          </Link>
        </div>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_3.jpg' alt='banner 3' />
          </Link>
        </div>
      </section>

      <section className='top_categories'>
        <div className='heading'>
          <h1><span><b>Top Categories </b></span> <span>Of The Month</span></h1>
          <hr />
        </div>

        <div className='catagories_wrapper'>

          {top_categories.map((category) => (
            <div key={category.id} className='category'>
              <div className='category_content'>
                <Link to='/'>{category.title}</Link>
                <p>({category.items} Items)</p>
              </div>
              <div className='img_container'>
                <img src={category.image} alt={`category ${category.id + 1}`} />
              </div>
            </div>
          ))}

        </div>
      </section>

      <section className='banner_section_2'>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_4.jpg' alt='banner 4' />
          </Link>
        </div>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_5.jpg' alt='banner 5' />
          </Link>
        </div>
      </section>

      {/* <ComputerProducts />

      <ClothingProducts />

      <FurnitureProducts /> */}

      <section className='banner_section_3'>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_6.jpg' alt='banner 6' />
          </Link>
        </div>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_7.jpg' alt='banner 7' />
          </Link>
        </div>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_8.jpg' alt='banner 8' />
          </Link>
        </div>
      </section>

    </div>
  )
}

export default Index