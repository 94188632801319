/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBagShopping, faHeadset } from '@fortawesome/free-solid-svg-icons'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { faUser, faHeart } from '@fortawesome/free-solid-svg-icons';

import { getAll } from '../../product/productcategory/productcategory.service';
import './Navbar.css'

const Navbar = () => {
  const [Activelink, setActivelink] = useState(0);
  const [scroll, setScroll] = useState(false);
  const [navbarToggled, setNavbarToggled] = useState(false);
  const [categoriesData, setCategoriesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false);
  const [totalCartItems, setTotalCartItems] = useState(0);
  const [wishlistCount, setWishlistCount] = useState(0);
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState(0);

  const navLinks = [
    { path: '/', label: 'HOME' },
    // { path: '/about', label: 'ABOUT' },
    { path: '/shop', label: 'SHOP' },
    { path: '/about', label: 'ABOUT' },
    { path: '/contact', label: 'CONTACT' },
    { path: '/dashboard', label: 'DASHBOARD' }
  ];

  const cart = JSON.parse(localStorage.getItem('cart')) || [];
  const wishlistItems = JSON.parse(localStorage.getItem('wishlist')) || [];

  useEffect(() => {
    const updateCartCount = () => {
      const totalItems = cart.reduce((sum, item) => sum + item.quantity, 0);
      setTotalCartItems(totalItems);
    };

    setWishlistCount(wishlistItems.length);

    updateCartCount();
  }, [cart, wishlistItems]);


  const toggleLoginModal = () => {
    setLoginModalVisible(!loginModalVisible);
  };

  const toggleRegisterModal = () => {
    setRegisterModalVisible(!registerModalVisible);
  };

  const openLoginModal = () => {
      setLoginModalVisible(true);
      setRegisterModalVisible(false);
    };
  
    const openRegisterModal = () => {
      setRegisterModalVisible(true);
      setLoginModalVisible(false);
    };

    const openForgotPasswordModal = () => {
      setForgotPasswordModalVisible(true);
      setLoginModalVisible(false);
      setRegisterModalVisible(false);
    };

    const toggleForgotPasswordModal = () => {
      setForgotPasswordModalVisible(!forgotPasswordModalVisible);
      if (loginModalVisible) setLoginModalVisible(false);
      if (registerModalVisible) setRegisterModalVisible(false);
    };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAll();
        setCategoriesData(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleNavbarToggler = () => {
    setNavbarToggled(!navbarToggled)
  }

  const handleScroll = () => {
    if (window.scrollY >= 150) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    setNavbarToggled(false);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (navbarToggled) {
      document.body.classList.add('no_scroll');
    } else {
      document.body.classList.remove('no_scroll');
    }
  }, [navbarToggled]);

  return (
    <div className='navbar'>
      <div className='top_bar'>
        <div className='topbar_wrapper'>

          <div className='topbar_left'>Welcome to Aseel's Closet</div>

          <div className='topbar_right'>

            <div className='topbar_link_wrapper'>
              <Link to='/' className='topbar_link'>Privacy Policy</Link>
            </div>
            <hr />

            <div className='topbar_link_wrapper'>
              <Link className='topbar_link'>Terms & Conditions</Link>
            </div>
          </div>
        </div>
      </div>

      <div className='header'>
        <div className='header_wrapper'>

          <div className='logo_container'>
            <img src='/assets/logo.png' />
          </div>

          <div className='helpline'>
            <FontAwesomeIcon icon={faHeadset} />
            <div className='helpline_content'>
              <p>Hotline Free:</p>
              <p><b>06-900-6789-00</b></p>
            </div>
          </div>

          <div className='search_bar'>
            <form className='header_form'>
              <input
                className='search_input'
                placeholder='Search Products...'
              />
              <button className='submit_btn' type='submit'>Search</button>
            </form>
          </div>

          <div className='components'>
            <div className='comp_wrapper'>
              <FontAwesomeIcon icon={faUser} />
              <p>Account</p>
              <ul className='dropdown_menu'>
                <li><p onClick={toggleLoginModal}>Login</p></li>
                <li><p onClick={toggleRegisterModal}>Register</p></li>
              </ul>
            </div>
            <Link to={'/wishlist'} className='comp_wrapper'>
              <div className='value_marker'>{wishlistCount}</div>
              <FontAwesomeIcon icon={faHeart} />
              <p>Wishlist</p>
            </Link>
            <Link to={'/cart'} className='comp_wrapper'>
              <div className='value_marker'>{totalCartItems}</div>
              <FontAwesomeIcon icon={faBagShopping} />
              <p>My Cart</p>
            </Link>

          </div>

          <div className={`nav_toggler ${navbarToggled ? "cross" : ""}`} onClick={handleNavbarToggler}>
              <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
                <path className="line line1" stroke='black' strokeWidth='4' strokeLinecap='round' strokeMiterlimit='10' d='M4 8h24' />
                <path className="line line2" stroke='black' strokeWidth='4' strokeLinecap='round' strokeMiterlimit='10' d='M4 16h24' />
                <path className="line line3" stroke='black' strokeWidth='4' strokeLinecap='round' strokeMiterlimit='10' d='M4 24h24' />
              </svg>
          </div>

        </div>
      </div>

      <div className={`nav_menus ${scroll ? 'fixed' : ''} ${navbarToggled ? 'toggled' : ''}`}>
        <div className='menus_wrapper'>
        <ul className='nav_links'>
          {navLinks.map((link, index) => (
            <li key={index}>
              <Link
                to={link.path}
                className={`link ${activeLink === index ? 'active' : ''}`}
                onClick={() => setActiveLink(index)}
              >
                {link.label}
              </Link>
            </li>
          ))}
        </ul>
        </div>
      </div>

        {loginModalVisible && (
            <div className="modal">
                <div className="modal_content">
                    <span className="close_button" onClick={toggleLoginModal}>&times;</span>
                    <h2>Aseels</h2>
                    <form>
                        <div className="form_group">
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" required />
                        </div>
                        <div className="form_group">
                            <label htmlFor="password">Password:</label>
                            <input type="password" id="password" name="password" required />
                        </div>
                        <a href="#forgot-password" className="forgot_password_link" onClick={openForgotPasswordModal}>Forgot Password?</a>
                        <button type="submit" className="login_button">Login</button>
                        <button type="button" className="google_login_button">
                            <img src='images/google.svg' alt='google icon' />
                            <p>Continue with Google</p>
                        </button>
                        <p className='divider'>
                            <span></span>
                            <p>or</p>
                            <span></span>
                        </p>
                        <p className='no_account'>
                            <span>Don't Have an account?</span>
                            <a href="#signup" className="register_link" onClick={openRegisterModal}>Sign Up</a>
                        </p>
                    </form>
                </div>
            </div>
        )}

        {registerModalVisible && (
                <div className="modal">
                <div className="modal_content">
                    <span className="close_button" onClick={toggleRegisterModal}>&times;</span>
                    <h2>Aseels</h2>
                    <form>
                    <div className="form_group">
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="name" name="name" required />
                    </div>
                    <div className="form_group">
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" required />
                    </div>
                    <div className="form_group">
                        <label htmlFor="password">Password:</label>
                        <input type="password" id="password" name="password" required />
                    </div>
                    <button type="submit" className="login_button">Register</button>
                        <button type="button" className="google_login_button">
                            <img src='images/google.svg' alt='google icon' />
                            <p>Continue with Google</p>
                        </button>
                        <p className='divider'>
                            <span></span>
                            <p>or</p>
                            <span></span>
                        </p>
                        <p className='no_account'>
                            <span>Already a member?</span>
                            <a href="#signup" className="register_link" onClick={openLoginModal}>Sign in</a>
                        </p>
                    </form>
                </div>
                </div>
        )}

        {forgotPasswordModalVisible && (
            <div className="modal">
            <div className="modal_content">
                <span className="close_button" onClick={toggleForgotPasswordModal}>&times;</span>
                <h2>Aseels</h2>
                <form>
                <div className="form_group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" required />
                </div>
                <button type="submit" className="reset_password_button login_button">Reset Password</button>
                </form>
            </div>
            </div>
        )}
    </div>
  )
}

export default Navbar;
