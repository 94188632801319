
import { faBaseball, faBaseballBatBall, faBolt, faBriefcaseMedical, faCameraRetro, faChevronLeft, faChevronRight, faFemale, faFootball, faGears, faGlasses, faHatCowboy, faList, faMale, faScrewdriverWrench, faSprayCan, faSprayCanSparkles, faSun, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useRef  } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Product from '../product/product.view';

export const renderDropdownView = (validCategoriesData) =>(
<div className='categories'>
  {Object.keys(validCategoriesData).map((parentCategory, index) => (
        <div className='category_list' key={index}>
        <span>{parentCategory}</span>
        <ul>
            {validCategoriesData[parentCategory].map((cat, idx) => (
            <li key={idx}>
                <a href="#category">{cat.productcategory_NAME}</a>
            </li>
            ))}
        </ul>
        </div>
    ))};
    </div>
)

export const renderFooterView = (validCategoriesData) =>(
  <div className='categories'>
          <div className='category_list'>
          <ul>
          {validCategoriesData.map((parentCategory, index) => (
              <li key={index}>
                  <a href="#category">{parentCategory.productcategory_NAME}</a>
              </li>
              ))}
          </ul>
          </div>
      </div>
  )

export const renderSliderView = (validCategoriesData) => (
  <div className='categories'>
        <div className='catagories_wrapper'>
          {validCategoriesData.map((category, index) => (
            <div key={index} className='category'>
              <div className='category_content'>
                <Link to='/'>{category.productcategory_NAME}</Link>
                {/* <p>(6 Items)</p> */}
              </div>
              <div className='img_container'>
                <img src={category.productcategoryimage_URL} alt={`category ${index + 1}`} />
              </div>
            </div>
          ))}
        </div>
  </div>
);

export const RenderListView = (validCategoriesData) => {

  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    const formattedCategoryName = category.productcategory_NAME.replace(/\s+/g, '-');
    navigate(`/categories/${category.productcategory_ID}/${formattedCategoryName}`);
  };

    // Recursive function to render nested subcategories
    const renderSubCategories = (sublist) => {
        return (
            <ul className='category_menu'>
                {sublist.map((subcat, index) => (
                    <li key={index}>
                        <Link to='/'>
                            {subcat.productcategory_NAME}
                        </Link>
                        {subcat.sublist && subcat.sublist.length > 0 && renderSubCategories(subcat.sublist)}
                    </li>
                ))}
            </ul>
        );
    };
  return (
    <div className='departments'>
        <div className='heading'>
            <FontAwesomeIcon icon={faList} />
            <h4>SHOP BY DEPARTMENT</h4>
        </div>
        <div className='dep_wrapper'>
        {
            validCategoriesData.map((category, index) => (
                <div 
                  className='category' 
                  key={index} 
                  onClick={() => handleCategoryClick(category)}
                >
                    <div className='category_name'>
                        <FontAwesomeIcon icon={faFemale} />
                        <p>{category.productcategory_NAME}</p>
                    </div>
                    {category.sublist && category.sublist.length > 0 && (
                        <FontAwesomeIcon className='left' icon={faChevronRight} />
                    )}
                    {/* Render subcategories if they exist */}
                    {category.sublist && category.sublist.length > 0 && renderSubCategories(category.sublist)}
                </div>
            ))
        }
        </div>
    </div>
 )};


 export const RenderFilterView = (validCategoriesData) => {
    const [selectedCategories, setSelectedCategories] = useState({});
  
    const handleClick = (categoryId) => {
      setSelectedCategories((prevState) => ({
        ...prevState,
        [categoryId]: !prevState[categoryId],
      }));
    };
  
    // Recursive function to render nested categories
    const renderNestedCategories = (sublist) => {
      return (
        <ul>
          {sublist.map((subcat,index) => (
            <li key={index}>
              <div
                onClick={() => handleClick(subcat.productcategory_ID)}
                style={{
                  cursor: 'pointer',
                  color: selectedCategories[subcat.productcategory_ID] ? 'black' : 'var(--gray)',
                  fontWeight: selectedCategories[subcat.productcategory_ID] ? '600' : '500',
                }}
              >
                {subcat.productcategory_NAME}
              </div>
              {/* Render subcategories if they exist and are selected */}
              {subcat.sublist && subcat.sublist.length > 0 && renderNestedCategories(subcat.sublist)}
            </li>
          ))}
        </ul>
      );
    };
  
    return (
      <div className='cat_list'>
        {renderNestedCategories(validCategoriesData)}
      </div>
    );
  };

  
export const CategoriesPage = (validCategoriesData) => {
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
  
    useEffect(() => {
      if (validCategoriesData?.length > 0) {
        setSelectedCategory(validCategoriesData[0].productcategory_ID);
      }
    }, [validCategoriesData]);
  
    const handleCategorySelect = (categoryId) => {
      setSelectedCategory(categoryId);
      navigate(`/categories?category=${categoryId}`);
    };
  
    return (
      <div className="categories_page_wrapper">
        <div className="categories_page">
          <div className="categories_page_wrapper">
            <header className="categories-header">
              <h1>All Categories</h1>
              <p>
                2,681 Deals & Offers. Explore Home & Living Deals, Sales & Discounts
                in the UK - November 2024
              </p>
            </header>
            <CategoryTabs
              categories={validCategoriesData}
              selectedCategory={selectedCategory}
              onCategorySelect={handleCategorySelect}
              selectedSubCategory={selectedSubCategory}
              onSubCategorySelect={setSelectedSubCategory}
            />
          </div>
        </div>
      </div>
    );
  };
  
  const CategoryTabs = ({
    categories,
    selectedCategory,
    onCategorySelect,
    selectedSubCategory,
    onSubCategorySelect,
  }) => {
    const scrollRef = useRef(null);
  
    const scroll = (direction) => {
      if (scrollRef.current) {
        const scrollAmount = 200;
        scrollRef.current.scrollBy({
          left: direction === "left" ? -scrollAmount : scrollAmount,
          behavior: "smooth",
        });
      }
    };
  
    const renderNestedSubcategories = (subcategories) => {
      return subcategories.map((subcategory) => (
        <div key={subcategory.productcategory_ID} className="subcategory-item">
          <button
            onClick={() => onSubCategorySelect(subcategory.productcategory_ID)}
            className={`subcategory-button ${
              selectedSubCategory === subcategory.productcategory_ID ? "selected" : ""
            }`}
          >
            {subcategory.productcategory_NAME}
          </button>
          {selectedSubCategory === subcategory.productcategory_ID &&
            subcategory.sublist?.length > 0 && (
              <div className="nested-subcategory">
                {renderNestedSubcategories(subcategory.sublist)}
              </div>
            )}
        </div>
      ));
    };
  
    return (
    <>
      <div className="category-tabs-container">
        <button onClick={() => scroll("left")} className="scroll-button left">
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div ref={scrollRef} className="category-scroll">
          {categories.map((category) => (
            <button
              key={category.productcategory_ID}
              onClick={() => onCategorySelect(category.productcategory_ID)}
              className={`category-button ${
                selectedCategory === category.productcategory_ID ? "selected" : ""
              }`}
            >
              <FontAwesomeIcon icon={category.icon} className="category-icon" />
              <span>{category.productcategory_NAME}</span>
            </button>
          ))}
        </div>
        <button onClick={() => scroll("right")} className="scroll-button right">
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      {categories.map((category) =>
        category.productcategory_ID === selectedCategory &&
        category.sublist?.length > 0 ? (
          <div>
              <h2>Popular Sub-Categories</h2>
              <div key={category.productcategory_ID} className="subcategory-container">
                {renderNestedSubcategories(category.sublist)}
              </div>
          </div>
        ) : null
      )}
    </>
    );
  };
  