

import React, { useState } from 'react';
import './Tabs.css';
import { useLocation } from 'react-router';
import Hero from '../hero/Hero';

const Tab = ({ tabsConfig, defaultActiveTab }) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const location = useLocation();

  const isAccountPage = location.pathname === '/account';

  return (
    <div className="dashboard">
      <div className={`tab_headers ${isAccountPage ? 'account_tabs' : ''}`}>
        <div className="tabs_list">
          {tabsConfig.map((tab) => (
            <div
              key={tab.key}
              className={`tab ${activeTab === tab.key ? 'active' : ''}`}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.label}
            </div>
          ))}
        </div>
        {isAccountPage && (
            <button className="logout_btn">
              Logout
            </button>
          )}
      </div>

      <div className="tab_content">
        {tabsConfig.map((tab) => (
          activeTab === tab.key && (
            <div key={tab.key} className="tab_details">
              {tab.content}
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default Tab;
