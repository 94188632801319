export const parseNestedJSON = (data) => {
  if (typeof data === 'string') {
    try {
      return parseNestedJSON(JSON.parse(data)); // Attempt to parse the string
    } catch {
      return data; // Return the string if it's not a valid JSON
    }
  } else if (Array.isArray(data)) {
    return data.map(parseNestedJSON); // Recursively parse array elements
  } else if (data !== null && typeof data === 'object') {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, parseNestedJSON(value)])
    );
  }
  return data; // Return data as-is if it's neither string, array, nor object
};
