// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs {
    display: inline-block;
  }
  
.breadcrumbs_list {
    list-style: none;
    display: flex;
    gap: 10px;
    color: var(--dark);
    padding: 0;
  }
  
.breadcrumb_item a {
    text-decoration: none;
    color: var(--dark);
    font-size: 1rem;
  }
  
.breadcrumb_item.current {
    color: var(--dark);
    font-weight: bold;
    cursor: pointer;
  }
  
.breadcrumb_item a:hover {
    text-decoration: underline;
  }`, "",{"version":3,"sources":["webpack://./src/components/breadcrumbs/Breadcrumbs.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;EACvB;;AAEF;IACI,gBAAgB;IAChB,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,UAAU;EACZ;;AAEF;IACI,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;EACjB;;AAEF;IACI,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;EACjB;;AAEF;IACI,0BAA0B;EAC5B","sourcesContent":[".breadcrumbs {\n    display: inline-block;\n  }\n  \n.breadcrumbs_list {\n    list-style: none;\n    display: flex;\n    gap: 10px;\n    color: var(--dark);\n    padding: 0;\n  }\n  \n.breadcrumb_item a {\n    text-decoration: none;\n    color: var(--dark);\n    font-size: 1rem;\n  }\n  \n.breadcrumb_item.current {\n    color: var(--dark);\n    font-weight: bold;\n    cursor: pointer;\n  }\n  \n.breadcrumb_item a:hover {\n    text-decoration: underline;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
