import { useState } from 'react';
import { FaCreditCard, FaPaypal, FaAmazon, FaTruck, FaDollarSign, FaHome, FaShoppingCart } from 'react-icons/fa';
import './Checkout.css'; // Import the CSS file
import Summary from '../../components/summary/summary,view';
import Hero from '../../components/hero/Hero';

 const Checkout = () => {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [sameAsBilling, setSameAsBilling] = useState(false);

  const inputClasses = 'input_field';

  return (
    <div className="checkout_page">
      {/* <Hero title={'Shopping Cart'} /> */}

      <div className="main_grid">
        <div className="form_section">
          {/* Billing Information */}
          <div className="section">
            <h2 className="section_title">Billing Information</h2>
            <div className="form_group">
              <div className="row">
                <div className="field">
                  <input type="text" id="billingFirstName" className={inputClasses} placeholder="First Name" />
                </div>
                <div className="field">
                  <input type="text" id="billingLastName" className={inputClasses} placeholder="Last Name" />
                </div>
              </div>
              <div className="field">
                <input type="text" id="billingAddress" className={inputClasses} placeholder="Address" />
              </div>
              <div className="row">
                <div className="field">
                  <input type="text" id="billingCity" className={inputClasses} placeholder="City" />
                </div>
                <div className="field">
                  <input type="text" id="billingZipCode" className={inputClasses} placeholder="Zip Code" />
                </div>
              </div>
              <div className="row">
                <div className="field">
                  <input type="text" id="billingEmail" className={inputClasses} placeholder="Email" />
                </div>
                <div className="field">
                  <input type="text" id="billingPhone" className={inputClasses} placeholder="Phone Number" />
                </div>
              </div>
              <div className="field">
                <select id="billingCountry" className={inputClasses}>
                  <option value="">Select a country</option>
                  <option value="us">United States</option>
                  <option value="ca">Canada</option>
                  <option value="uk">United Kingdom</option>
                </select>
              </div>
            </div>
          </div>

          {/* Shipping Information */}
          <div className="section">
            <h2 className="section_title">Shipping Information</h2>
            <div className="form_group">
              <div className="checkbox_group">
                <input
                  type="checkbox"
                  id="sameAsBilling"
                  checked={sameAsBilling}
                  onChange={(e) => setSameAsBilling(e.target.checked)}
                  className="checkbox_input"
                />
                <label htmlFor="sameAsBilling">Same as billing address</label>
              </div>
              {!sameAsBilling && (
                <>
                  <div className="row">
                    <div className="field">
                      <input type="text" id="shippingFirstName" className={inputClasses} placeholder="First Name" />
                    </div>
                    <div className="field">
                      <input type="text" id="shippingLastName" className={inputClasses} placeholder="Last Name" />
                    </div>
                  </div>
                  <div className="field">
                    <input type="text" id="shippingAddress" className={inputClasses} placeholder="Address" />
                  </div>

                  <div className="row">
                    <div className="field">
                      <input type="text" id="shippingCity" className={inputClasses} placeholder="City" />
                    </div>
                    <div className="field">
                      <input type="text" id="shippingZipCode" className={inputClasses} placeholder="Zip Code" />
                    </div>
                  </div>

                  <div className="row">
                    <div className="field">
                      <input type="text" id="shippingEmail" className={inputClasses} placeholder="Email" />
                    </div>
                    <div className="field">
                      <input type="text" id="shippingPhone" className={inputClasses} placeholder="Phone Number" />
                    </div>
                  </div>
                
                  <div className="field">
                    <select id="shippingCountry" className={inputClasses}>
                      <option value="">Select a country</option>
                      <option value="us">United States</option>
                      <option value="ca">Canada</option>
                      <option value="uk">United Kingdom</option>
                    </select>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Payment Method */}
          <div className="section">
            <h2 className="section_title">Payment Method</h2>
            <div className="payment_methods">
              <div className="radio_group">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="cash-on-delivery"
                  checked={paymentMethod === 'cash-on-delivery'}
                  onChange={() => setPaymentMethod('cash-on-delivery')}
                  className="radio_input"
                />
                <label>
                  <FaDollarSign className="icon" />
                  Cash on Delivery
                </label>
              </div>
              <div className="radio_group">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="credit-card"
                  checked={paymentMethod === 'credit-card'}
                  onChange={() => setPaymentMethod('credit-card')}
                  className="radio_input"
                />
                <label>
                  <FaCreditCard className="icon" />
                  Credit/Debit Card
                </label>
              </div>
              <div className="radio_group">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="paypal"
                  checked={paymentMethod === 'paypal'}
                  onChange={() => setPaymentMethod('paypal')}
                  className="radio_input"
                />
                <label>
                  <FaPaypal className="icon" />
                  PayPal
                </label>
              </div>
              <div className="radio_group">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="amazon"
                  checked={paymentMethod === 'amazon'}
                  onChange={() => setPaymentMethod('amazon')}
                  className="radio_input"
                />
                <label>
                  <FaAmazon className="icon" />
                  Amazon Pay
                </label>
              </div>
              
            </div>

            {/* Payment Details */}
            {paymentMethod === 'credit-card' && (
              <div className="payment_details">
                <div className="fields">
                  <input type="text" id="cardHolderName" className={inputClasses} placeholder="Card Holder Name" />
                
                </div>
                <div className="fields">
                  <input type="text" id="cardNumber" className={inputClasses} placeholder="Card Number" />
                </div>
                <div className="row">
                  <div className="fields">
                    <input type="text" id="expiryDate" className={inputClasses} placeholder="Expiry Date" />
                  </div>
                  <div className="fields">
                    <input type="text" id="cvc" className={inputClasses} placeholder="CVC" />
                  </div>
                </div>
              </div>
            )}
            {paymentMethod === 'paypal' && (
              <div className="payment_note">
                <p>You will be redirected to PayPal to complete your payment.</p>
              </div>
            )}
            {paymentMethod === 'amazon' && (
              <div className="payment_note">
                <p>You will be redirected to Amazon Pay to complete your payment.</p>
              </div>
            )}
            {paymentMethod === 'cash-on-delivery' && (
              <div className="payment_note">
                <p>You will pay in cash when your order is delivered.</p>
              </div>
            )}
          </div>
        </div>

        {/* Order Summary */}
        <Summary page={'Order'} />
      </div>
    </div>
  );
}

export default Checkout