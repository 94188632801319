import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

import ProductCard from './productcard/productcard.view'
import { getAll } from '../productitem/productitem.service';
import dummyProducts from './data.js'

const Product = ({items, view}) => {
  const [productsData, setProductsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAll();
        setProductsData(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={`products_list ${view}`}>
      <ProductCard 
        number={items} 
        productsData={productsData && productsData} 
        // productsData={productsData && productsData.length > 0 ? productsData : dummyProducts} 
      />
    </div>
  )
}

export default Product