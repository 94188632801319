import React, { useEffect, useState } from 'react';
import './Cart.css';
import Counter from '../../components/counter/Counter';
import { Link } from 'react-router-dom';
import Hero from '../../components/hero/Hero';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import Summary from '../../components/summary/summary.view';
import { calculateTotal } from '../../utils/CartUtils';

const Cart = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    setProducts(cart); 
    setLoading(false);
  }, []);

  const handleRemove = (productId) => {
    const updatedCart = products.filter(item => item.product.productitem_ID !== productId);
    setProducts(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const handleQuantityChange = (productId, newQuantity) => {
    const updatedCart = products.map(item => {
      if (item.product.productitem_ID === productId) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setProducts(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="cart_container">
      <Hero title={'Shopping Cart'} />
      <div className="cart_content">
        <div className='cart_left'>
          <table className="cart_table">
            <thead>
              <tr>
                <th></th>
                <th>Product</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {products.length === 0 ? (
                <tr>
                  <td colSpan="5">Your cart is empty</td>
                </tr>
              ) : (
                products.map((item) => {
                  console.log('item',item)
                  return (
                    <>
                   <tr key={item.product.productitem_ID}>
                    <td className="">
                      <img src="https://www.limelight.pk/cdn/shop/files/DSC02421_533x.jpg?v=1720611663" alt={item.product.productitem_NAME} className="product_image" />
                    </td>
                    <td className='cart_product'>
                     <span className="product_name">{item.product.productitem_DETAIL.productitem_NAME}</span>
                     <span className="">{item.product.productitem_DETAIL.product_DETAIL.product_NAME}</span>
                     <span className="">RS.{item.product.productitem_DETAIL.product_DETAIL.unit_PRICE ? item.product.productitem_DETAIL.product_DETAIL.unit_PRICE : 3999}</span>
                    </td>
                    <td>
                      <Counter
                        quantity={item.quantity}
                        setQuantity={(newQuantity) => handleQuantityChange(item.product.productitem_ID, newQuantity)}
                      />
                    </td>
                    <td>RS.{calculateTotal(item)}</td>
                    <td>
                      <button className="remove" onClick={() => handleRemove(item.product.productitem_ID)}>
                        <FontAwesomeIcon icon={faRemove} />
                      </button>
                    </td>
                   </tr>
                  </>
                  )
              })
              )}
              {/* <tr>
                    <td className="">
                      <img src="https://www.limelight.pk/cdn/shop/files/DSC02421_533x.jpg?v=1720611663" alt={''} className="product_image" />
                    </td>
                    <td className='cart_product'>
                     <span className="product_name">3 Piece Lawn Suit-Embroidered</span>
                     <span className="">Zara Shahjahan</span>
                     <span className="">RS.{2000}</span>
                    </td>
                    <td>
                      <Counter
                        quantity={1}
                        setQuantity={(newQuantity) => handleQuantityChange(1, newQuantity)}
                      />
                    </td>
                    <td>RS.{2000}</td>
                    <td>
                      <button className="remove" onClick={() => handleRemove(1)}>
                        <FontAwesomeIcon icon={faRemove} />
                      </button>
                    </td>
              </tr> */}
            </tbody>
          </table>

          <div className="cart_actions">
            <Link to={'/'} className="back_to_shop">Update Cart</Link>
            <div className="coupon_section">
              <input type="text" className="coupon_input" placeholder="Enter your coupon" />
              <button className="apply_coupon">Apply</button>
            </div>
          </div>
        </div>

        <Summary page={'Cart'} products={products} />
      </div>
    </div>
  );
};

export default Cart;
