
import { faBaseball, faBaseballBatBall, faBolt, faBriefcaseMedical, faCameraRetro, faChevronRight, faFemale, faFootball, faGears, faGlasses, faHatCowboy, faList, faMale, faScrewdriverWrench, faSprayCan, faSprayCanSparkles, faSun, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const renderDropdownView = (validCategoriesData) =>(
<div className='categories'>
  {Object.keys(validCategoriesData).map((parentCategory, index) => (
        <div className='category_list' key={index}>
        <span>{parentCategory}</span>
        <ul>
            {validCategoriesData[parentCategory].map((cat, idx) => (
            <li key={idx}>
                <a href="#category">{cat.productcategory_NAME}</a>
            </li>
            ))}
        </ul>
        </div>
    ))};
    </div>
)

export const renderFooterView = (validCategoriesData) =>(
  <div className='categories'>
          <div className='category_list'>
          <ul>
          {validCategoriesData.map((parentCategory, index) => (
              <li key={index}>
                  <a href="#category">{parentCategory.productcategory_NAME}</a>
              </li>
              ))}
          </ul>
          </div>
      </div>
  )

export const renderSliderView = (validCategoriesData) => (
  <div className='categories'>
        <div className='catagories_wrapper'>
          {validCategoriesData.map((category, index) => (
            <div key={index} className='category'>
              <div className='category_content'>
                <Link to='/'>{category.productcategory_NAME}</Link>
                <p>(6 Items)</p>
              </div>
              <div className='img_container'>
                <img src={`/assets/category_3.jpg`} alt={`category ${index + 1}`} />
              </div>
            </div>
          ))}
        </div>
  </div>
);

export const renderListView = (validCategoriesData) => {
    // Recursive function to render nested subcategories
    const renderSubCategories = (sublist) => {
        return (
            <ul className='category_menu'>
                {sublist.map((subcat, index) => (
                    <li key={index}>
                        <Link to='/'>
                            {subcat.productcategory_NAME}
                        </Link>
                        {subcat.sublist && subcat.sublist.length > 0 && renderSubCategories(subcat.sublist)}
                    </li>
                ))}
            </ul>
        );
    };
  return (
    <div className='departments'>
        <div className='heading'>
            <FontAwesomeIcon icon={faList} />
            <h4>SHOP BY DEPARTMENT</h4>
        </div>
        <div className='dep_wrapper'>
        {
            validCategoriesData.map((category, index) => (
                <Link to='/shop' className='category' key={index}>
                    <div className='category_name'>
                        <FontAwesomeIcon icon={faFemale} />
                        <p>{category.productcategory_NAME}</p>
                    </div>
                    {category.sublist && category.sublist.length > 0 && (
                        <FontAwesomeIcon className='left' icon={faChevronRight} />
                    )}
                    {/* Render subcategories if they exist */}
                    {category.sublist && category.sublist.length > 0 && renderSubCategories(category.sublist)}
                </Link>
            ))
        }
        </div>
    </div>
 )};


 export const RenderFilterView = (validCategoriesData) => {
    const [selectedCategories, setSelectedCategories] = useState({});
  
    const handleClick = (categoryId) => {
      setSelectedCategories((prevState) => ({
        ...prevState,
        [categoryId]: !prevState[categoryId],
      }));
    };
  
    // Recursive function to render nested categories
    const renderNestedCategories = (sublist) => {
      return (
        <ul>
          {sublist.map((subcat,index) => (
            <li key={index}>
              <div
                onClick={() => handleClick(subcat.productcategory_ID)}
                style={{
                  cursor: 'pointer',
                  color: selectedCategories[subcat.productcategory_ID] ? 'black' : 'var(--gray)',
                  fontWeight: selectedCategories[subcat.productcategory_ID] ? '600' : '500',
                }}
              >
                {subcat.productcategory_NAME}
              </div>
              {/* Render subcategories if they exist and are selected */}
              {subcat.sublist && subcat.sublist.length > 0 && renderNestedCategories(subcat.sublist)}
            </li>
          ))}
        </ul>
      );
    };
  
    return (
      <div className='cat_list'>
        {renderNestedCategories(validCategoriesData)}
      </div>
    );
  };