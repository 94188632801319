import React, { useState } from 'react'

import './newarrivals.css'
import products from '../../../data/Products_data.json'
import ProductCrousel from '../ProductCrousel/ProductCrousel'
import Product from '../../product/product/product.view'

const NewArrivals = () => {

    const [selectedCategory, setSelectedCategory] = useState('Digital')

    const handleCategory = (category) => {
        setSelectedCategory(category)
    }

    return (
        <section className='computer_products'>
            <div className='heading'>
                <div className='heading_wrapper'>
                    <h1><span><b>New Arrivals </b></span></h1>
                    <hr />
                </div>
                {/* <div className='sub_products'>
                    <div className={`sub_link ${selectedCategory === 'Digital' ? 'active' : ''}`} onClick={() => handleCategory('Digital')}>1 Piece</div>
                    <div className={`sub_link ${selectedCategory === 'Game & Toys' ? 'active' : ''}`} onClick={() => handleCategory('Game & Toys')}>2 Piece</div>
                    <div className={`sub_link ${selectedCategory === 'Smart Phones' ? 'active' : ''}`} onClick={() => handleCategory('Smart Phones')}>3 Piece</div>
                </div> */}
            </div>

            <div className='product_content'>
                {/* <div className='product_img'>
                    <img src='/assets/product_1.jpg' />
                </div> */}
                <div className='product_crousal'>
                    {/* <ProductCrousel product={products['Computer Products'][selectedCategory]} /> */}
                    <Product items={8}/>
                </div>
            </div>

        </section>
    )
}

export default NewArrivals