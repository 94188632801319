// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.title{
   width: -moz-fit-content;
   width: fit-content;
   text-align: left;
   width: 100%;
   margin-bottom: 40px;
}

.title h1{
    font-size: 30px;
    font-weight: 600 !important;
    color: var(--dark);
    text-transform: capitalize;
}

.title span{
    width: 10%;
    height: 2px;
    background-color: var(--secondary) !important;
}

.title p{
    color: var(--gray);
}`, "",{"version":3,"sources":["webpack://./src/components/section_title/Title.css"],"names":[],"mappings":";AACA;GACG,uBAAkB;GAAlB,kBAAkB;GAClB,gBAAgB;GAChB,WAAW;GACX,mBAAmB;AACtB;;AAEA;IACI,eAAe;IACf,2BAA2B;IAC3B,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,UAAU;IACV,WAAW;IACX,6CAA6C;AACjD;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["\n.title{\n   width: fit-content;\n   text-align: left;\n   width: 100%;\n   margin-bottom: 40px;\n}\n\n.title h1{\n    font-size: 30px;\n    font-weight: 600 !important;\n    color: var(--dark);\n    text-transform: capitalize;\n}\n\n.title span{\n    width: 10%;\n    height: 2px;\n    background-color: var(--secondary) !important;\n}\n\n.title p{\n    color: var(--gray);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
