import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faHeart as filledHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as emptyHeart } from '@fortawesome/free-regular-svg-icons';
import './productcard.view.css';

const ProductCard = ({ productsData, number }) => {
  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState([]);
  
  useEffect(() => {
    const storedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    setWishlist(storedWishlist);
  }, []);

  const handleAddToCart = (product) => {
    localStorage.setItem('product', JSON.stringify(product));
    navigate(`/${product.productitem_NAME.replace(/\s+/g, '-')}?productId=${product.productitem_ID}`);
  };

  const toggleWishlist = (product) => {
    const productID = product.product_ID;
    const isProductInWishlist = wishlist.some(p => p.product_ID === productID);
    
    let newWishlist;
    if (isProductInWishlist) {
      newWishlist = wishlist.filter(p => p.product_ID !== productID);
    } else {
      const productWithQuantity = { ...product, quantity: 1 };
      newWishlist = [...wishlist, productWithQuantity];
    }

    setWishlist(newWishlist);
    localStorage.setItem('wishlist', JSON.stringify(newWishlist));
  };


  const productsToDisplay = number ? productsData?.slice(0, number) : productsData;
  // const dummyProducts = new Array(8 - (productsToDisplay?.length || 0)).fill({});
  
  return (
    <div className="voucher_cards">
      {productsToDisplay?.map((product) => {
        const parsedProductDetail = product.product_DETAIL ? JSON.parse(product.product_DETAIL) : {};
        const isInWishlist = wishlist.some(p => p.product_ID === product.product_ID);
        
        return (
          <div key={product.productitem_ID} className="v_card" onClick={() => handleAddToCart(product)}>
            <img src={product.productitemimage_URL} alt={parsedProductDetail.product_NAME} className="card_image" />
            
            <div className="card_content">
              <h3 className="card_heading">{product.productitem_NAME}</h3> 
              <div className="card_row">
                <span className="card_source">{parsedProductDetail.product_NAME}</span>
              </div>
              <div className='wishlist_row'>
                <p className="card_expiration">
                  RS. {parsedProductDetail.purchase_PRICE}
                </p>
                <FontAwesomeIcon
                  icon={isInWishlist ? filledHeart : emptyHeart}
                  className={isInWishlist ? 'wishlist_icon filled' : 'wishlist_icon'}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleWishlist(product);
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductCard;
