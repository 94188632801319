import React, { useEffect, useState } from 'react';
import '../cart/Cart.css';
import Counter from '../../components/counter/Counter';
import { Link } from 'react-router-dom';
import Hero from '../../components/hero/Hero';

const Wishlist = () => {
  const [wishlist, setWishlist] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const storedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    setWishlist(storedWishlist);
  }, []);

  const handleAddToCart = (product) => {
    // Add product to cart logic
    // For example: localStorage.setItem('cart', JSON.stringify([...cart, product]));
  };

  const handleQuantityChange = (productId, newQuantity) => {
    const updatedWishlist = wishlist.map(item => {
      if (item.productitem_ID === productId) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setProducts(updatedWishlist);
    localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
  };

  const handleRemoveFromWishlist = (productID) => {
    const newWishlist = wishlist.filter(product => product.product_ID !== productID);
    setWishlist(newWishlist);
    localStorage.setItem('wishlist', JSON.stringify(newWishlist));
  };

  return (
    <div className="cart_container wishlist_container">
      <Hero title={'Wishlist'} />
      {/* <h1 className="cart_heading">Wishlist ({wishlist.length})</h1> */}
      <div className="cart_content wishlist_content">
        <div className='cart_left wishlist_left'>
          <table className="cart_table">
            <thead>
              <tr>
                <th>Product</th>
                <th></th>
                <th>Quantity</th>
                <th>Subtotal</th>
                <th>Stock Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {wishlist.map(product => {
                const parsedProductDetail = product.product_DETAIL ? JSON.parse(product.product_DETAIL) : {};
                return (
                  <tr key={product.product_ID}>
                    <td className="">
                      <img src={product.image || 'https://www.limelight.pk/cdn/shop/files/DSC02421_533x.jpg?v=1720611663'} alt={parsedProductDetail.product_NAME} className="product_image" />
                    </td>
                    <td className='cart_product'>
                     <span className="product_name">{product.productitem_NAME}</span>
                     <span className="">Zara Shahjahan</span>
                     <span className="">RS.{parsedProductDetail.purchase_PRICE  ? parsedProductDetail.purchase_PRICE  : 3999}</span>
                    </td>
                    <td>
                      <Counter
                        quantity={product.quantity}
                        setQuantity={(newQuantity) => handleQuantityChange(product.productitem_ID, newQuantity)}
                      />
                    </td>
                    <td>Rs. 2000</td>
                    <td>In Stock</td>
                    <td className='action_buttons'>
                      <button className="add_to_cart" onClick={() => handleAddToCart(product)}>Add to Cart</button>
                      <button className="remove" onClick={() => handleRemoveFromWishlist(product.product_ID)}>Remove</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="cart_actions">
            <Link to={'/shop'} className="back_to_shop">Back to shop</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
