import { useState, useEffect, useRef } from "react";
import "./AboutPage.css";
import { stats, teamMembers, values } from "./data";

function FadeInWhenVisible({ children }) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    const currentRef = ref.current;

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div ref={ref} className={`fade_in ${isVisible ? "visible" : "hidden"}`}>
      {children}
    </div>
  );
}

const About = () => {
  const [activeTab, setActiveTab] = useState("story");
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentTeamMember, setCurrentTeamMember] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTeamMember((prev) => (prev + 1) % teamMembers.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="container">
      <h1 className="main_title">Our Story of Innovation</h1>

      <FadeInWhenVisible>
        <section className="section">
          <div className="tabs">
            {["story", "mission", "values"].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`tab_button ${activeTab === tab ? "active" : ""}`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
          <div className="tab_content">
            {activeTab === "story" && (
              <div className="tab_panel">
                <div className="panel_content">
                  <h2 className="panel_title">Our Journey</h2>
                  <p className="panel_text">
                    Founded in 2013, our e-commerce company has grown from a
                    small startup to a leading online marketplace. We've faced
                    challenges, celebrated victories, and learned valuable
                    lessons along the way. Founded in 2013, our e-commerce
                    company has grown from a small startup to a leading online
                    marketplace. We've faced challenges, celebrated victories,
                    and learned valuable lessons along the way.
                  </p>
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="expand_button"
                  >
                    {isExpanded ? "Read Less" : "Read More"}
                  </button>
                  {isExpanded && (
                    <div className="expanded_text">
                      <p>
                        Our journey has been marked by continuous innovation,
                        customer-centric approaches, and a commitment to
                        sustainability. We've expanded our product range,
                        improved our technology, and built a community of loyal
                        customers who share our vision for a better online
                        shopping experience.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
            {activeTab === "mission" && (
              <div className="tab_panel">
                <div className="panel_content">
                  <h2 className="panel_title">Our Mission</h2>
                  <p className="panel_text">
                    We strive to revolutionize the online shopping experience by
                    offering a curated selection of products, fostering
                    sustainable practices, and building lasting relationships
                    with our customers and partners.
                  </p>
                  <div className="image_container">
                    <img
                      src="/logo.jpeg?height=300&width=500"
                      alt="Our mission visualization"
                      width={500}
                      height={300}
                      className="image"
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab === "values" && (
              <div className="tab_panel">
                <h2 className="panel_title">Our Core Values</h2>
                <div className="values_grid">
                  {values.map((value, index) => (
                    <div key={index} className="value_card">
                      <div className="value_icon">{value.icon}</div>
                      <h3 className="value_title">{value.label}</h3>
                      <p className="value_description">{value.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>
      </FadeInWhenVisible>

      <FadeInWhenVisible>
        <section className="section">
          <h2 className="section_title">Our Impact</h2>
          <div className="stats_grid">
            {stats.map((stat, index) => (
              <div key={index} className="stat_card">
                <div className="stat_icon">{stat.icon}</div>
                <h3 className="stat_value">{stat.value}</h3>
                <p className="stat_label">{stat.label}</p>
              </div>
            ))}
          </div>
        </section>
      </FadeInWhenVisible>

      {/* <FadeInWhenVisible>
        <section className="section">
          <h2 className="section_title">Meet Our Team</h2>
          <div className="team_carousel">
            {teamMembers.map((member, index) => (
              <div
                key={member.name}
                className={`team_member ${
                  index === currentTeamMember ? "visible" : "hidden"
                }`}
              >
                <div className="members_image">
                  <img
                    src={member.image}
                    alt={member.name}
                    width={200}
                    height={200}
                    className="member_image"
                  />
                </div>
                <div className="member_info">
                  <h3 className="member_name">{member.name}</h3>
                  <p className="member_role">{member.role}</p>
                  <p className="member_bio">{member.bio}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </FadeInWhenVisible> */}

      {/* <FadeInWhenVisible>
        <section className="join_section">
          <h2 className="join_title">Join Our Journey</h2>
          <p className="join_text">
            We're always looking for passionate individuals to join our team and
            help us grow. Explore our current openings and be part of our
            exciting future!
          </p>
          <button className="join_button">
            View Career Opportunities
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </section>
      </FadeInWhenVisible> */}
    </div>
  );
}

export default About;