// utils.js

// Recursive function to parse deeply nested JSON strings
const parseNestedJson = (data) => {
    // Check if the input is an object or array
    if (typeof data === 'object' && data !== null) {
      for (const key in data) {
        if (typeof data[key] === 'string') {
          // Try parsing the string
          try {
            const parsed = JSON.parse(data[key]);
            // If parsing succeeds, replace the original string with the parsed object
            data[key] = parseNestedJson(parsed); // Recursively parse the nested object
          } catch (e) {
            // If parsing fails, keep the original string
            continue;
          }
        } else if (typeof data[key] === 'object') {
          // Recursively parse nested objects
          data[key] = parseNestedJson(data[key]);
        }
      }
    }
    return data;
  };
  
  export default parseNestedJson;
  