// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  border: 1px solid var(--dark);
  border-radius: 50px;
  }
  
  .counter button {
    background-color: var(--light);
    color: var(--dark);
    border: none;
    cursor: pointer;
    font-size: 25px;
  }
  
  .counter span {
    margin: 0 10px;
  }

  .quantity_selector {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  
  .quantity_selector .colors{
    width: 70%;
  }
  
  .quantity_selector button {
    width: 40px;
    height: 30px;
    cursor: pointer;
  }
  
  .quantity_selector span,
  .color_selection span {
    margin-right: 10px;
    font-size: 16px;
    text-align: center;
    width: 30%;
  }
  
  .counter button{
    margin: 0px 18px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/counter/Counter.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,6BAA6B;EAC7B,mBAAmB;EACnB;;EAEA;IACE,8BAA8B;IAC9B,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;EACjB;;EAEA;;IAEE,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".counter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 2px 10px;\n  border: 1px solid var(--dark);\n  border-radius: 50px;\n  }\n  \n  .counter button {\n    background-color: var(--light);\n    color: var(--dark);\n    border: none;\n    cursor: pointer;\n    font-size: 25px;\n  }\n  \n  .counter span {\n    margin: 0 10px;\n  }\n\n  .quantity_selector {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 50%;\n  }\n  \n  .quantity_selector .colors{\n    width: 70%;\n  }\n  \n  .quantity_selector button {\n    width: 40px;\n    height: 30px;\n    cursor: pointer;\n  }\n  \n  .quantity_selector span,\n  .color_selection span {\n    margin-right: 10px;\n    font-size: 16px;\n    text-align: center;\n    width: 30%;\n  }\n  \n  .counter button{\n    margin: 0px 18px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
