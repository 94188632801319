import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";


export const SummaryView = ({page}) => {

    const items = [
        { name: 'Product 1', quantity: 2, price: 29.99, image: 'https://www.alkaramstudio.com/cdn/shop/products/FW-31-23-Blue-2.jpg?v=1718548439' },
        { name: 'Product 2', quantity: 1, price: 49.99, image: 'https://www.alkaramstudio.com/cdn/shop/products/FW-31-23-Blue-2.jpg?v=1718548439' },
        { name: 'Product 3', quantity: 1, price: 49.99, image: 'https://www.alkaramstudio.com/cdn/shop/products/FW-31-23-Blue-2.jpg?v=1718548439' },
      ];
    return (
    <div className="cart_summary">
        <h2 className='summary_title'>{page} Summary</h2>
        {page == 'Order' && <div className="order_items">
            {items && items.map((item, index) => (
            <div key={index} className="order_item">
                <img src={item.image} alt={item.name} width={50} height={50} className="product_image" />
                <div className="item_info">
                <p className="item_name">{item.name}</p>
                <p className="item_quantity">Quantity: {item.quantity}</p>
                </div>
                <span className="item_price">${(item.price * item.quantity).toFixed(2)}</span>
            </div>
            ))}
        </div>}
        <div className='subtotal_container'>
        <div className="subtotal">
            <span className='key'>Subtotal</span>
            <span className='value'>PKR.{3000}</span>
            {/* <span className='value'>Rs.{calculateSubtotal()}</span> */}
        </div>
        <div className="subtotal">
            <span className='key'>Tax (Estimated for Pakistan)</span>
            <span className='value'>PKR.{3000}</span>
        </div> 
        <div className="subtotal">
            <span className='key'>Shipping (3-5 Business Days) </span>
            <span className='value'>Free</span>
        </div>
        </div>
        <div className="total">
        <span>Total</span>
        <span>PKR.{3000}</span>
        </div>
        {
        page == 'Order' ?
        <>
            <Link to={'/checkout'} className="checkout_btn">
                <FontAwesomeIcon className='icon' icon={faTruck} />
                Place Order
            </Link>
        </> :
        <>
            <Link to={'/checkout'} className="checkout_btn">
                Proceed to checkout
            </Link>
        </>
        } 
    </div>
    )
  };

export const OrderSummaryView = () => {
    <div className="cart_summary">
        <div className='subtotal_container'>
        <div className="subtotal">
            <span className='key'>Subtotal</span>
            <span className='value'>PKR.{3000}</span>
            {/* <span className='value'>Rs.{calculateSubtotal()}</span> */}
        </div>
        <div className="subtotal">
            <span className='key'>Tax (Estimated for Pakistan)</span>
            <span className='value'>PKR.{3000}</span>
        </div> 
        <div className="subtotal">
            <span className='key'>Shipping (3-5 Business Days) </span>
            <span className='value'>Free</span>
        </div>
        </div>
        <div className="total">
        <span>Total</span>
        <span>PKR.{3000}</span>
        </div>
    </div>
  };