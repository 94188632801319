import React from 'react'
import './Vouchers.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import VCard from '../../components/productCard/ProductCard'
import Title from '../../components/section_title/Title'

const Vouchers = () => {

  return (
    <div className='vouchers_page'>
        <section className="hero_section">
            <div className="content">
                <h1>Main Heading</h1>
                <h2>Subheading</h2>
                <div className="search_container">
                    <input type="text" className="search_input" placeholder="Search..." />
                    <button className="search_button">
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>
            </div>
        </section>

        <section className='voucher_cards_section'>
            <Title title={"The promo codes everyone's talking about"} desc={"Join the buzz and discover the promo codes everyone's taking about. This exclusive discounts are creating a stir- grab them now and save like never before!"} />
            <VCard />
        </section>
    </div>
  )
}

export default Vouchers
