import React, { useEffect, useState } from 'react'
import './Counter.css'

const Counter = ({ quantity, setQuantity }) => {

  const [count, setCount] = useState(quantity);

  useEffect(() => {
    setCount(quantity);
  }, [quantity]);

  const handleIncrement = () => {
    const newCount = count + 1;
    setCount(newCount);
    setQuantity(newCount);
  };

  const handleDecrement = () => {
    if (count > 1) { 
      const newCount = count - 1;
      setCount(newCount);
      setQuantity(newCount);
    }
  };
      
  return (
    <div className='counter'>
        <button onClick={handleDecrement}>-</button>
        <span>{quantity}</span>
        <button onClick={handleIncrement}>+</button>
    </div>
  )
}

export default Counter