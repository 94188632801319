// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price_display {
    font-family: Arial, sans-serif;
  }
  
  .prices {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .actual_price {
    color: #103178;
  }
  
  .other_prices {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .price_level {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 10px 0px;
    border-radius: 5px;
  }
  
  .price_level .quantity {
    font-weight: bold;
    color: #12a05c;
  }
  
  .price_level .price {
    color: var(--dark);
    font-weight: 500;
    margin-left: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/product/productitempricelevel/itempricelevel.view.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;EAChC;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,2BAA2B;IAC3B,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;EACnB","sourcesContent":[".price_display {\n    font-family: Arial, sans-serif;\n  }\n  \n  .prices {\n    font-size: 20px;\n    font-weight: bold;\n    margin-bottom: 10px;\n  }\n  \n  .actual_price {\n    color: #103178;\n  }\n  \n  .other_prices {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n  }\n  \n  .price_level {\n    display: flex;\n    justify-content: space-between;\n    padding: 10px 15px 10px 0px;\n    border-radius: 5px;\n  }\n  \n  .price_level .quantity {\n    font-weight: bold;\n    color: #12a05c;\n  }\n  \n  .price_level .price {\n    color: var(--dark);\n    font-weight: 500;\n    margin-left: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
