/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons'
import ProductCategory from '../product/productcategory/productcategory.view'
import { get } from '../product/productcategory/productcategory.service'

const Footer = () => {

    const [categoriesData, setCategoriesData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const result = await get();
            setCategoriesData(result);
          } catch (err) {
            setError(err);
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);

  return (
    <footer>
        <div className="footer_sections">
            <div className="footer_section">
                <h3>About Us</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut.</p> 
                <ul>
                    <li><a href="#"><span><FontAwesomeIcon icon={faPhone} /> +021-95-51-84</span></a></li>
                    <li><a href="#"><span><FontAwesomeIcon icon={faEnvelope} /> email@email.com</span></a></li>
                    <li><a href="#"><span><FontAwesomeIcon icon={faMapMarkerAlt} /> 1734 Stonecoal Road</span></a></li>
                </ul>
            </div>
            <div className="footer_section">
                <h3>Categories</h3>
                <ProductCategory view={'footer'} categoriesData={categoriesData}/>
            </div>
            <div className="footer_section">
                <h3>QUICK LINKS</h3>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/about-us">About</a></li>
                    <li><a href="/shop">Shop</a></li>
                    <li><a href="/contact">Contact</a></li>
                </ul>
            </div>
            <div className="footer_section">
                <h3>INFORMATION</h3>
                <ul>
                    <li><a href="#">Terms & Conditions</a></li>
                    <li><a href="#">Privacy Policy</a></li>
                    <li><a href="#">FAQs</a></li>
                </ul>
            </div>
        </div>
        <hr />
        <div className="footer_copyright">
            <p>&copy; 2024 CWIZTECH. All rights reserved.</p>
        </div>
    </footer>
  )
}

export default Footer
