import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Link } from 'react-router-dom';
import './summary.view.css'
import { SummaryView } from './summary.allviews';

const Summary = ({page}) => {

    // const getPrice = (item) => {
    //     return item.product.product_DETAIL.purchase_PRICE || 3999;
    //   };

    // const calculateSubtotal = () => {
    //     return products?.reduce((sum, item) => sum + (getPrice(item) * item.quantity), 0);
    //   }

  return (
    <SummaryView page={page} />
  )
}

export default Summary