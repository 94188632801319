// ProductCategory.js
import React from 'react';
import { renderDropdownView, renderSliderView, renderListView, RenderFilterView, renderFooterView } from './productcategory.allviews';

const ProductCategory = ({ view, categoriesData }) => {
  const validCategoriesData = categoriesData || [];
  console.log('validCategoriesData',validCategoriesData)

  const renderView = () => {
    switch (view) {
      case 'dropdown':
        return renderDropdownView(validCategoriesData);
      case 'footer':
        return renderFooterView(validCategoriesData);
      case 'slider':
        return renderSliderView(validCategoriesData);
      case 'list':
        return renderListView(validCategoriesData);
      case 'filter':
          return RenderFilterView(validCategoriesData);
      default:
        return null;
    }
  };

  return <>{renderView()}</>;
};

export default ProductCategory;
