import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Counter from "../counter/Counter";
import Summary from "../summary/summary.view";
import { OrderSummaryView } from "../summary/summary.allviews";
import { Link } from "react-router-dom";

export const OrderHistoryView = ({ onSelectOrder }) => {
    const orders = [
      { orderNo: '#1234', date: '2024-10-01', totalProducts: 3, totalPrice: '$150', status: 'Delivered' },
      { orderNo: '#5678', date: '2024-09-20', totalProducts: 2, totalPrice: '$80', status: 'Shipped' },
      { orderNo: '#9123', date: '2024-08-15', totalProducts: 1, totalPrice: '$50', status: 'Pending' },
    ];
  
    return (
      <div className="order_history">
        <h2>Order History</h2>
        <table className="order_history_table">
          <thead>
            <tr>
              <th>Order No</th>
              <th>Date</th>
              <th>Total Products</th>
              <th>Total Price</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
          {orders.map((order, index) => (
            <tr key={index} onClick={() => onSelectOrder(order)} className="order_row">
              <td className="order_no">{order.orderNo}</td>
              <td>{order.date}</td>
              <td>{order.totalProducts}</td>
              <td>{order.totalPrice}</td>
              <td className={`status ${order.status.toLowerCase()}`}>{order.status}</td>
            </tr>
          ))}
        </tbody>

        </table>
      </div>
    );
  };

export const OrderDetailView = ({ order, onBackToList }) => {
    return (
      <div className="order_details">
        <div className="order_table_header">
          <h2>Order Details</h2>
          <p className="back_btn" onClick={onBackToList} >Back to List</p>
        </div>
        <table className="cart_table">
            <thead>
              <tr>
                <th>Product</th>
                <th></th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
                  <tr>
                    <td className="">
                      <img src="https://www.limelight.pk/cdn/shop/files/DSC02421_533x.jpg?v=1720611663" alt={''} className="product_image" />
                    </td>
                    <td className='cart_product'>
                     <span className="product_name">Product name</span>
                     <span className="">Zara Shahjahan</span>
                    </td>
                    <td>PKR{3999}</td>
                    <td>3</td>
                    <td>PKR 7400</td>
                  </tr>
              <tr>
                    <td className="">
                      <img src="https://www.limelight.pk/cdn/shop/files/DSC02421_533x.jpg?v=1720611663" alt={''} className="product_image" />
                    </td>
                    <td className='cart_product'>
                     <span className="product_name">3 Piece Lawn Suit-Embroidered</span>
                     <span className="">Zara Shahjahan</span>
                    </td>
                    <td>PKR {2000}</td>
                    <td>1</td>
                    <td>PKR 4500</td>
              </tr>
            </tbody>
          </table>
          <div className="location_details">
                <div className="billing_address">
                   <h3>Billing Address</h3>
                   <div className="billing_details">
                      <p className="text_bold">Landon Kirby</p>
                      <p>1048 Shawala Road, Block E1, Lahore</p>
                      <p>eaxample@gmail.com</p>
                      <p>+92 340 840 7115</p>
                   </div>
                </div>
                <div className="shipping_address">
                   <h3>Shipping Address</h3>
                   <div className="billing_details">
                      <p className="text_bold">Landon Kirby</p>
                      <p>1048 Shawala Road, Block E1, Lahore</p>
                      <p>eaxample@gmail.com</p>
                      <p>+92 340 840 7115</p>
                   </div>
                </div>
                <div className="order_total">
                  <div className="cart_summary">
                    <div className='subtotal_container'>
                    <div className="subtotal">
                        <span className='key'>Subtotal</span>
                        <span className='value'>PKR.{3000}</span>
                        {/* <span className='value'>Rs.{calculateSubtotal()}</span> */}
                    </div>
                    <div className="subtotal">
                        <span className='key'>Tax</span>
                        <span className='value'>PKR.{3000}</span>
                    </div> 
                    <div className="subtotal">
                        <span className='key'>Shipping</span>
                        <span className='value'>Free</span>
                    </div>
                    </div>
                    <div className="total">
                    <span>Total</span>
                    <span>PKR.{3000}</span>
                    </div>
                  </div>
                </div>
            </div>
      </div>
    );
  };
