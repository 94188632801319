/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faTshirt,
  faMale,
  faChild,
  faGem,
  faSprayCan,
  faShoePrints,
  faFutbol,
  faMobileAlt,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import "./Categories.css"; // Import the external CSS file
import { getAll as getProducts } from "../../components/product/productitem/productitem.service";
import Product from "../../components/product/product/product.view";
import { get } from '../../components/product/productcategory/productcategory.service';
import ProductCategory from "../../components/product/productcategory/productcategory.view";


const Categories = () => {
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [categoriesData, setCategoriesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await get();
        setCategoriesData(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    // <h1>categories</h1>
    <ProductCategory view={'page'} categoriesData={categoriesData}/>
  );
};

export default Categories;
