import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';

import './mainSlider.css';

const MainSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0); // Track the current slide

    useEffect(() => {
        const firstCaption = document.querySelector('.caption');
        if (firstCaption) {
            firstCaption.classList.add('animate');
        }
    }, []); // This runs only once when the component mounts

    const handleSlideChangeEnd = () => {
        const captions = document.querySelectorAll('.caption');
        captions.forEach((caption, index) => {
            if (index === currentSlide) {
                caption.classList.add('animate');
            }
        });
    };

    // Handle slide change to reset animations
    const handleSlideChangeStart = (swiper) => {
        setCurrentSlide(swiper.activeIndex);
        const captions = document.querySelectorAll('.caption');
        captions.forEach((caption) => {
            caption.classList.remove('animate'); 
        });
    };

    return (
        <>
            <div className="swiper_outer_div">
                <div className="swiper_body">
                    <div className="swiper_inner_div">
                        <Swiper
                            navigation={true}
                            modules={[Navigation, Autoplay]}
                            className="mySwiper"
                            loop={true}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            speed={1000}
                            onSlideChangeTransitionStart={handleSlideChangeStart}
                            onSlideChangeTransitionEnd={handleSlideChangeEnd} // Trigger when slide transition ends
                        >
                            <SwiperSlide className='slide_1'>
                                {/* <div className="caption">
                                    <p className='p1'>BEST CHOICE OF THE YEAR</p>
                                    <h1>HIGH-END PHONES</h1>
                                    <p className='p2'>BEST CHOICE OF THE YEAR</p>
                                    <div className='btn'><Link>Discover Now</Link></div>
                                </div> */}
                            </SwiperSlide>

                            <SwiperSlide className='slide_2'>
                                {/* <div className="caption">
                                    <p className='p1'>XBOX WIRELESS CONTROLLER</p>
                                    <h1>SPORT EDITION</h1>
                                    <p className='p2'>EQUIP YOURSELF WITH XBOX WIRELESS CONTROLLER</p>
                                    <Link>Discover Now</Link>
                                </div> */}
                            </SwiperSlide>

                            <SwiperSlide className='slide_3'>
                                {/* <div className="caption">
                                    <p className='p1'>SURROUND RGB GAMING HEADSET</p>
                                    <h1>TOP HEADSETS</h1>
                                    <p className='p2'>PLATTAN 2 BLUETOOTH RAINBOW EDITION</p>
                                    <Link>Discover Now</Link>
                                </div> */}
                            </SwiperSlide>
                            <SwiperSlide className='slide_4'>
                                {/* <div className="caption">
                                    <p className='p1'>SURROUND RGB GAMING HEADSET</p>
                                    <h1>TOP HEADSETS</h1>
                                    <p className='p2'>PLATTAN 2 BLUETOOTH RAINBOW EDITION</p>
                                    <Link>Discover Now</Link>
                                </div> */}
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainSlider;
