import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import VCard from '../../components/productCard/ProductCard';
import './Product.css';
import Counter from '../../components/counter/Counter';
import { getOne } from '../../components/product/productitem/productitem.service';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
// import { productTabData, productTabsConfig } from './TabsData';
import Tabs from '../../components/tabs/Tabs';
import parseNestedJson from '../../utils';
import { ProductDetailView, ShippingDetailView } from '../../components/product/productdetails/productdetails.allviews';

const Product = () => {
  const [selectedColor, setSelectedColor] = useState('Red');
  const [productData, setProductData] = useState(null);
  const [desc, setDesc] = useState(null);
  const [img, setImg] = useState(null);
  const [price, setPrice] = useState(null);
  const [brand, setBrand] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const sliderRef = useRef(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get('productId');

  useEffect(() => {
    const fetchData = async (id) => {
      try {
          const result = await getOne(id);
          if (result && result.length > 0) {
            const parsedResult = parseNestedJson(result[0]);
            const directImageURL = parsedResult.productitemimage_PATH.replace(
              "thumbnail?id=",
              "uc?export=view&id="
            );
            console.log('Parsed API Result:', parsedResult);
            setProductData(parsedResult);
            setDesc(parsedResult.productitem_DETAIL.productitem_DESC)
            setImg(directImageURL)

        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData(productId);
  }, [productId]);

  const productTabsConfig = [
    {
      key: 'details',
      label: 'Product Details',
      content: <ProductDetailView productDesc={desc}/>,
    },
    {
      key: 'shipping',
      label: ' Shipping and Handling',
      content: <ShippingDetailView />,
    },
  ];

  const images = [
    "https://www.alkaramstudio.com/cdn/shop/products/FW-31-23-Blue-2.jpg?v=1718548439",
    "https://www.alkaramstudio.com/cdn/shop/products/FW-31-23-Blue-1.jpg?v=1718548448",
    "https://www.alkaramstudio.com/cdn/shop/products/FW-31-23-Blue-3.jpg?v=1718548445",
  ];

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    sliderRef.current?.scrollBy({ left: 80, behavior: "smooth" });
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    sliderRef.current?.scrollBy({ left: -80, behavior: "smooth" });
  };

  const handleAddToCart = () => {
    const cartItem = {
      product: productData,
      quantity: quantity,
    };
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    const existingProductIndex = cart.findIndex(item => item.product.id === productData.id);
  
    if (existingProductIndex !== -1) {
      cart[existingProductIndex].quantity += quantity;
    } else {
      cart.push(cartItem);
    }
  
    localStorage.setItem('cart', JSON.stringify(cart));
    toast.success('Product added to cart');
  };
  

  return (
    <div className="single_product_page">
      <div className="product_card">
        <div className='product_card_left'>
          <div className="image_section">
            <div className="main_image">
              <img
                src={images[currentImageIndex]}
                alt={`Product view ${currentImageIndex + 1}`}
                width={400}
                height={500}
                className="main_image_img"
              />
            </div>
            <div className="thumbnail_container">
              <div ref={sliderRef} className="thumbnail_slider">
                {images?.map((src, index) => (
                  <div
                    key={index}
                    className={`thumbnail ${index === currentImageIndex ? "active_thumbnail" : ""}`}
                    onClick={() => setCurrentImageIndex(index)}
                  >
                    <img
                      src={src}
                      alt={`Thumbnail view ${index + 1}`}
                      width={80}
                      height={80}
                      className="thumbnail_img"
                    />
                  </div>
                ))}
              </div>
              {images.length > 4 && (
                <>
                  <button onClick={prevImage} className="slider_button left">
                    <FontAwesomeIcon icon={faChevronLeft} className="icon" />
                  </button>
                  <button onClick={nextImage} className="slider_button right">
                    <FontAwesomeIcon icon={faChevronRight} className="icon" />
                  </button>
                </>
              )}
            </div>
          </div>

          {
            productData && 
            <>
              <div className="product_details">
                <h2 className="product_title">{productData.productitem_DETAIL.productitem_NAME}</h2>
                <p className="product_code">{productData?.productitem_DETAIL.product_DETAIL.product_NAME}</p>
                <p className='product_code'>Product Code: {productData.productitem_DETAIL.product_DETAIL.product_CODE}</p>
                <div className='pdetail_price'>
                  <p className='prices'>
                    <span className='actual_price'>PKR {productData.productitem_DETAIL.product_DETAIL.purchase_PRICE}</span>
                    <span className='discounted'>PKR 3,378 </span>
                  </p>
                  <p className='stock_status'>
                    <FontAwesomeIcon icon={faCheckCircle} />  In Stock
                  </p>  
                </div>
                <div className='stock_detail'>
                  <p>Hurry! only <span className='stock_left'>14</span> left in stock.</p>
                  <p></p>
                </div>
                <div className='product_desc'>
                  <p></p>
                </div>
                <div className="color_and_quantity">
                  <div className="quantity_selector">
                    <Counter quantity={quantity} setQuantity={setQuantity} />
                  </div>
                  <a href="/cart" className="get_deal_btn" onClick={handleAddToCart}>Add to Cart</a>
                </div>
                <Tabs 
                  tabsConfig={productTabsConfig} 
                  defaultActiveTab="details" 
                />
              </div>
            </>
          }
        </div>
      </div>
     <div className='about_deal_card'> 
        <div className='deal-wrapper'>
          <h3 className='section-title'>You May Also Like</h3>
          <div className='stores'>
            <VCard />
          </div>
        </div>
     </div>
    </div>
  );
};

export default Product;
