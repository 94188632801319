import React from "react";
import { Link } from "react-router-dom";

import './PrivacyPolicy.css'


const PrivacyPolicy = () => {
  return (
    <div className='main_faq'>
      {/* <div className='head_part'>
        <div className='arc'>
          <h1>
            Privacy <br />
            Policy
          </h1>
        </div>
      </div> */}

      <div className='queryP'>
        <div className='main_cont'>
          <h1 className='heading1'>Privacy Policy for Aseel's Closet</h1>

          <p>
            At <b> Aseel's Closet ("Aseel's")</b>, your privacy is our priority. We are committed to protecting
            your personal information and ensuring a safe and secure shopping experience. This Privacy Policy 
            outlines how we collect, use, and safeguard your information when you interact with our website.
          </p>

          <h2>1. Collection of Personal Information</h2>
          <p>We collect personal information when you:</p>
          <ol>
            <li>Inquire about our products and services.</li>
            <li>Place an order</li>
            <li>Use our website.</li>
          </ol>

          <p><strong>The information we collect may include:</strong></p>
          <ol>
            <li>Name</li>
            <li>Contact details (phone number, email address)</li>
            <li>Physical address</li>
            <li>Payment details (credit card or other payment information)</li>
          </ol>

          <h2>2. How We Use Your Information</h2>
          <p>We use your personal information to:</p>
          <ol>
            <li>Fulfill your orders and ensure timely delivery of our premium designs.</li>
            <li>Provide customer support and respond to inquiries</li>
            <li>Share updates, promotions, and offers tailored to your preferences</li>
            <li>Improve our website and services.</li>
          </ol>

          <h2>3. Sharing Your Information</h2>
          <p>
            We respect your privacy and do not sell or share your information with third parties, 
            except to trusted partners who assist in processing payments, shipping orders, or 
            marketing services. These partners are bound by confidentiality agreements to protect your data.
          </p>

          <h2>4. Cookies and Tracking Technologies</h2>
          <p>
            Aseel's Closet uses cookies to enhance your shopping experience. Cookies help us personalize your 
            visit, save your preferences, and provide you with tailored recommendations.
          </p>

          <h2>5. Protecting Your Information</h2>
          <p>
            We implement robust security measures to ensure your information is safe, including 
            encryption and secure servers for storing personal details.
          </p>

          <p>
            We use this information to improve the functionality and content of
            our website.
          </p>

          <h2>6. Your Rights</h2>
          <p>
            You have the right to access, update, or delete your personal information. 
            To exercise your rights, please contact us at asmach26@gmail.com.
          </p>

          <h2>7. Updates to Our Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. Changes will be posted 
            on this page with a revised date. We encourage you to review it regularly.
          </p>

          <h2>8. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please<Link href="/contactUs">contact us</Link>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
